@tailwind base;
@tailwind components;
@tailwind utilities;

  html, body {
    background: rgb(23, 23, 23);
    user-select: none !important;
  }

  button, span, input, textarea {
      --tw-ring-color: rgba(0, 0, 0, 0) !important;
  }

  img {
    pointer-events: none;
  }

  .scrollable {
    overflow-y: scroll !important;
  }  
  .scrollable::-webkit-scrollbar {
    width: 0.55rem !important;
  }
  .scrollable::-webkit-scrollbar-thumb {
    border-radius: 0.375rem !important;
    background-color: #333a46 !important;
  }
  .scrollable::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  
  .unscrollable {
    overflow-y: hidden !important;
  }

  .h-104 {
    height: 26rem;
  }

  .h-150 {
    height: 37.5rem;
  }